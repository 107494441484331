import { icons } from "react-icons";
import { Icons } from "../components/Icons/Icons";

export const rentPageMainData = {
  title: "Ihr Transporter wartet – Jetzt in wenigen Schritten mieten!",
  description:
    "Günstig und unkompliziert können Sie bei uns in Lübeck einen Transporter mieten, ob einen Tag, eine Woche, einen Monat oder länger: Wir bieten Ihnen stets ein faires Preis - Leistungsverhältnis und flexible Abhol – und Rückgabezeiten (auch am Wochenende oder später am Abend)",
};

export const rentPageFleetData = {
  title: "Unsere Fahrzeuge",
  icon: Icons.transporter,

  fleet: [
    {
      title: "Anzahl von Transportern: 9",
      description:
        "Wir bieten Ihnen eine Auswahl an zuverlässigen Transportern, die sich für verschiedenste Anforderungen eignen. Zu unserem Fuhrpark gehören der Ford Transit, der VW Crafter sowie der Renault Pritsche. Egal, ob Sie große Lasten transportieren oder eine flexible Lösung für Ihre Anforderungen suchen – bei uns finden Sie den passenden Transporter für Ihre Bedürfnisse.",
    },
    {
      title: "Spezifikation Ford Transit: 3m x 1,70m x 2,25m",
      description:
        "Der Ford Transit ist perfekt für den Transport von kleineren Möbelstücken wie Sofas, Tischen, Waschmaschinen oder Kühlschränken. Mit seiner großzügigen Ladefläche und robusten Bauweise eignet er sich hervorragend für Umzüge oder den Transport von größeren Gegenständen, die dennoch nicht zu viel Platz benötigen",
    },
    {
      title: "Spezifikation VW Crafter Lang: 6,9m x 1,9m x 2,5m",
      description:
        "Der VW Crafter ist perfekt für den Transport von kleineren Möbelstücken wie Sofas, Tischen, Waschmaschinen oder Kühlschränken. Mit seiner großzügigen Ladefläche und robusten Bauweise eignet er sich hervorragend für Umzüge oder den Transport von größeren Gegenständen, die dennoch nicht zu viel Platz benötigen",
    },
    {
      title: "Spezifikation Renault Pritsche: 5,8m x 2m x 2,3m",
      description:
        "Die Renault Pritsche eignet sich perfekt für den Transport von voluminösen oder sperrigen Gütern. Mit ihrer offenen Ladefläche können Sie problemlos Baustellenmaterialien wie Holz, Steine oder Maschinen transportieren. Auch Gartenabfälle, Möbel oder größere Geräte wie Waschmaschinen, Kühlschränke und große Möbelstücke passen gut auf die Pritsche. Dank der flexiblen Ladefläche haben Sie viele Möglichkeiten, Ihre Güter sicher und schnell zu transportieren.",
    },
  ],
};

export const rentPageCarPriceData = {
  title: "Unsere Preise",
  icon: Icons.coinIcon,
  description:
    "Bei uns können Sie Transporter zu fairen und transparenten Preisen mieten. Wir achten darauf, dass unsere Preise nicht unnötig hoch sind, sodass Sie für Ihre Bedürfnisse immer ein gutes Angebot erhalten. Egal, ob für einen Umzug oder einen größeren Transport – bei uns können Sie sich darauf verlassen, dass Sie zu einem günstigen Preis eine zuverlässige Lösung finden.",
  wochentariftitle: "Wochentarif",
  wochentarif: "Montag bis Freitag",
  wochenendtariftitle: "Wochenendtarif",
  wochenendtarif: "Samstag und Sonntag",
  standNo:"Stand 01.2025",
  standEn:"Stand 01.2025",
  wochenTarif: [
    {
      art: "pro Tag",
      kilometer: "bis 150km",
      price: "70€",
    },
    {
      art: "pro Tag",
      kilometer: "bis 300km",
      price: "100€",
    },
    {
      art: "pro Tag",
      kilometer: "mehr als 300km",
      price: "auf Anfrage",
    },
  ],
  wochenEndtarif: [
    {
      art: "pro Tag",
      kilometer: "bis 150km",
      price: "100€",
    },
    {
      art: "pro Tag",
      kilometer: "bis 300km",
      price: "150€",
    },
    {
      art: "pro Tag",
      kilometer: "mehr als 300km",
      price: "auf Anfrage",
    }
  ],
  info: [
    {
      title: "**Bei jeder Reservierung ist eine Kaution von 100 € zu hinterlegen.",
    },
    {
      title: "**Bei Bedarf auch mit Fahrer.",
    },
    {
      title:
        "**Es können zusätzliche Kosten anfallen, wenn die Bedingungen nicht eingehalten werden. Bitte lesen Sie diese unbedingt vor der Reservierung!",
    },
  ],
};

export const rentPageImageList = [
  "./assets/images/transporter/1.jpeg",
  "./assets/images/transporter/2.jpeg",
  "./assets/images/transporter/3.jpeg",
  "./assets/images/transporter/4.jpeg",
  "./assets/images/transporter/5.jpeg",
];

export const rentPageBedingungData = {
  title: "Bedingungen",
  icon: Icons.warning,
};

export const rentBedingungen = [
  {
    title: "1. Sauberkeit und Rückgabe",
    points: [
      "1.1. Das Fahrzeug muss in sauberem Zustand zurückgegeben werden. Andernfalls können Reinigungsgebühren von mindestens 50€ erhoben werden.",
      "1.2. Der Tank muss auf dem gleichen Niveau wie bei der Übergabe sein. Eine Abweichung wird mit einer Strafgebühr berechnet.",
    ],
  },
  {
    title: "2. Unfall und Schaden",
    points: [
      "2.1. Im Falle eines Unfalls ist die Polizei sofort zu benachrichtigen, und auch uns ist umgehend Bescheid zu geben.",
      "2.2. Wenn der Fahrer des Fahrzeugs schuld ist, wird der Schaden an dem Fahrzeug von der Kaution abgezogen. Es können jedoch weitere Kosten je nach Schaden anfallen.",
    ],
  },
  {
    title: "3. Verbotene Nutzung",
    points: [
      "3.1. Der Transport von Schrott oder gefährlichen Gütern ist strikt untersagt.",
      "3.2. Bei Verstoß gegen diese Regel wird eine Strafgebühr von mindestens 3000€ erhoben.",
    ],
  },
  {
    title: "4. Zusatzfahrer",
    points: [
      "4.1. Ein Zusatzfahrer muss bei der Buchung angegeben werden. Für jeden weiteren Fahrer fällt eine Gebühr an.",
    ],
  },
  {
    title: "5. GPS und Tracking",
    points: [
      "5.1. Das Fahrzeug ist mit einem GPS-Tracker ausgestattet. Das Tracking dient der Sicherstellung der Fahrzeugnutzung und kann im Falle eines Missbrauchs überprüft werden.",
    ],
  },
  {
    title: "6. Strafgebühren",
    points: [
      "6.1. Strafgebühren werden dem Mieter in Rechnung gestellt, wenn er gegen die Bedingungen verstößt (z.B. falsches Parken, verspätete Rückgabe des Fahrzeugs).",
      "6.2. Der Mieter übernimmt die Verantwortung für alle entstehenden Strafgebühren.",
    ],
  },
  {
    title: "7. Mindestalter und Führerschein",
    points: [
      "7.1. Der Mieter muss mindestens 21 Jahre alt sein und einen gültigen Führerschein besitzen, der seit mindestens 2 Jahren gültig ist.",
    ],
  },
  {
    title: "8. Verantwortung des Fahrers",
    points: [
      "8.1. Der Mieter ist für das Fahrzeug während der Mietzeit verantwortlich und haftet für Schäden, die während dieser Zeit entstehen, auch bei Diebstahl oder Vandalismus.",
    ],
  },
];
