import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { HelmetProvider } from 'react-helmet-async';

import Navbar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import GlobalStyle from "./globalStyles";
import Rent from "./pages/Rent";
import KFZ from "./pages/KFZ";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Delivery from "./pages/Delivery";
import SelfKfz from "./pages/SelfKfz";
import NotFound from "./pages/NotFound";
import AboutUs from "./pages/AboutUs";
import Credits from "./pages/Credits";
import Copyright from "./pages/Copyright";
import Privacy from "./pages/Privacy";

//const TRACKING_ID = "G-2QCJXTNZJ7"; // OUR_TRACKING_ID
const TRACKING_ID = "G-Q266L649DG";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <HelmetProvider>
    <Router>
    <GlobalStyle/>
      <Navbar />
      <Routes>
      <Route path="/rent" element={<Rent />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/rent" element={<Rent />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/diy" element={<SelfKfz />} />
        <Route path="/kfz" element={<KFZ />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/copyright" element={<Copyright />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
    <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Ablehnen"
        cookieName="myCookieConsent"
        style={{ background: "#2B373B", color: "#ffffff" }}
        buttonStyle={{ background: "#4CAF50", color: "#ffffff", fontSize: "14px" }}
        declineButtonStyle={{ background: "#f44336", color: "#ffffff", fontSize: "14px" }}
        expires={999} overlay
        onAccept={() => {
          // Aktion nach Akzeptieren der Cookies
          console.log("Cookies akzeptiert");
        }}
        onDecline={() => {
          // Aktion nach Ablehnen der Cookies
          console.log("Cookies abgelehnt");
        }}
      >
       Um Ihnen ein optimales Nutzungserlebnis zu bieten und unsere Website kontinuierlich zu verbessern, verwenden wir Cookies. Dabei arbeiten wir mit ausgewählten Partnern zusammen, um Ihnen personalisierte Inhalte und Werbung bereitzustellen. Weitere Informationen sowie Einstellungsmöglichkeiten finden Sie in unseren Datenschutzhinweisen.
      </CookieConsent>
    </HelmetProvider>
  );
}

export default App;
