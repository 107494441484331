export const HomePageMetaData = {
    title: "LTM Transport und Autoservice",
    meta: [
      {
        name: "description",
        content: "Bei LTM bieten wir eine Vielzahl von Dienstleistungen an, um Ihren Transport- und Autoservicebedürfnissen gerecht zu werden. Von der Vermietung von Transportern bis hin zur schnellen Lieferung und professionellen Autoreparatur. Entdecken Sie unsere breite Palette an Services und verlassen Sie sich auf unsere Erfahrung und Zuverlässigkeit. Kontaktieren Sie uns noch heute, um mehr zu erfahren und Ihre Anforderungen zu besprechen!",
      },
      {
        name: "keywords",
        content: "Transporter mieten, KFZ Werkstatt, Auto Reparatur, Selbsthilfewerkstatt, Lübeck",
      },
      {
        property: "og:title",
        content: "Transporter Mieten & KFZ Werkstatt in Lübeck bei LTM",
      },
      {
        property: "og:description",
        content: "Finden Sie Transporter zum Mieten bei LTM und lassen Sie Ihr Fahrzeug in unserer Werkstatt reparieren – jetzt auch als Selbsthilfewerkstatt.",
      },
      {
        property: "og:image",
        content: "https://ltm-hl.de/assets/images/logo/logo.png",
      },
      {
        property: "og:url",
        content: "https://ltm-hl.de",
      },
      {
        property: "og:type",
        content: "website",
      },
    ],
    canonical: "https://ltm-hl.de",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "LTM Transport und Autoservice",
      description: "Bei LTM bieten wir eine Vielzahl von Dienstleistungen an, um Ihren Transport- und Autoservicebedürfnissen gerecht zu werden. Von der Vermietung von Transportern bis hin zur schnellen Lieferung und professionellen Autoreparatur. Entdecken Sie unsere breite Palette an Services und verlassen Sie sich auf unsere Erfahrung und Zuverlässigkeit. Kontaktieren Sie uns noch heute, um mehr zu erfahren und Ihre Anforderungen zu besprechen!",
      breadcrumb: {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "LTM Transport und Autoservice",
          "item": "https://ltm-hl.de",
        },
      ],
    },
    },
  }

  export const RentPageMetaData = {
    title: "Mieten | LTM",
    meta: [
      {
        name: "description",
        content: "Mieten Sie einen Transporter bei LTM in Lübeck für Ihren Umzug, Transport oder andere Bedürfnisse. Unsere Fahrzeuge sind zuverlässig, gut gepflegt und bieten Ihnen alles, was Sie für einen erfolgreichen Transport benötigen.",
      },
      {
        name: "keywords",
        content: "Transporter Mieten, Umzugstransporter, Transport Lübeck, Fahrzeugvermietung, Mietwagen Lübeck",
      },
      {
        property: "og:title",
        content: "Transporter Mieten bei LTM in Lübeck für jeden Bedarf",
      },
      {
        property: "og:description",
        content: "Mieten Sie bequem einen Transporter bei LTM in Lübeck. Flexibel und zuverlässig – für Umzüge, Transporte und mehr.",
      },
      {
        property: "og:image",
        content: "https://ltm-hl.de/assets/images/logo/logo.png",
      },
      {
        property: "og:url",
        content: "https://ltm-hl.de/rent",
      },
      {
        property: "og:type",
        content: "website",
      },
    ],
    canonical: "https://ltm-hl.de/rent",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Service",
      name: "Transporter Mieten in Lübeck",
      description: "Mieten Sie einen Transporter bei LTM in Lübeck. Unsere Transporter sind perfekt für Umzüge und Transporte. Buchen Sie noch heute online.",
      serviceType: "Transporter Vermietung",
      provider: {
        "@type": "Organization",
        name: "LTM Transport und Autoservice",
        url: "https://ltm-hl.de",
      },
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "LTM Transport und Autoservice",
          "item": "https://ltm-hl.de",
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Mieten | LTM",
          "item": "https://ltm-hl.de/rent",
        }
      ],
    },
  }

  export const KfzPageMetaData = {
    title: "KFZ | LTM",
    meta: [
      {
        name: "description",
        content: "Benötigen Sie eine zuverlässige KFZ-Reparatur in Lübeck? Bei LTM bekommen Sie schnelle, professionelle Reparaturen für alle Fahrzeugmarken. Vertrauen Sie auf unsere Erfahrung für Ihre Fahrzeugwartung und Reparaturen.",
      },
      {
        name: "keywords",
        content: "KFZ Reparatur, Auto Werkstatt, Fahrzeug Reparatur Lübeck, Autoreparaturen, Auto Service Lübeck",
      },
      {
        property: "og:title",
        content: "KFZ Reparatur bei LTM in Lübeck – Schnelle und professionelle Reparaturen",
      },
      {
        property: "og:description",
        content: "Profitieren Sie von zuverlässigen KFZ-Reparaturen bei LTM in Lübeck. Wir bieten Wartung und Reparaturen für alle Fahrzeugmarken an.",
      },
      {
        property: "og:image",
        content: "https://ltm-hl.de/assets/images/logo/logo.png",
      },
      {
        property: "og:url",
        content: "https://ltm-hl.de/kfz",
      },
      {
        property: "og:type",
        content: "website",
      },
    ],
    canonical: "https://ltm-hl.de/kfz",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Service",
      name: "KFZ Reparatur bei LTM in Lübeck",
      description: "Schnelle und zuverlässige KFZ-Reparaturen für alle Fahrzeugmarken bei LTM in Lübeck. Unser Werkstatt-Team sorgt für die Wartung und Reparatur Ihres Fahrzeugs.",
      serviceType: "KFZ Reparatur",
      provider: {
      "@type": "Organization",
      name: "LTM Transport und Autoservice",
      url: "https://ltm-hl.de",
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "LTM Transport und Autoservice",
          "item": "https://ltm-hl.de",
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "KFZ | LTM",
          "item": "https://ltm-hl.de/kfz",
        }
      ],
    },
    },
  }
  export const SelfKfzPageMetaData = {
    title: "Selbsthilfewerkstatt | LTM",
    meta: [
      {
        name: "description",
        content: "Bei LTM in unserer Selbsthilfewerkstatt in Lübeck können Sie Ihr Auto mit unserer Unterstützung selbst reparieren. Mieten Sie eine Werkstatt und profitieren Sie von professionellen Werkzeugen und Beratung.",
      },
      {
        name: "keywords",
        content: "Selbsthilfewerkstatt, Auto Reparatur, Fahrzeug reparieren, Werkstatt mieten, Lübeck, Auto Service",
      },
      {
        property: "og:title",
        content: "Selbsthilfewerkstatt bei LTM in Lübeck – Reparieren Sie Ihr Fahrzeug selbst",
      },
      {
        property: "og:description",
        content: "Nutzen Sie unsere Selbsthilfewerkstatt in Lübeck, um Ihr Auto mit professioneller Unterstützung selbst zu reparieren. Alles, was Sie brauchen, ist hier.",
      },
      {
        property: "og:image",
        content: "https://ltm-hl.de/assets/images/logo/logo.png",
      },
      {
        property: "og:url",
        content: "https://ltm-hl.de/diy",
      },
      {
        property: "og:type",
        content: "website",
      },
    ],
    canonical: "https://ltm-hl.de/diy",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Selbsthilfewerkstatt in Lübeck",
      description: "In unserer Selbsthilfewerkstatt können Sie Ihr Fahrzeug mit unserer Unterstützung und professionellen Werkzeugen selbst reparieren.",
      serviceType: "Selbsthilfewerkstatt",
      provider: {
      "@type": "Organization",
      name: "LTM Transport und Autoservice",
      url: "https://ltm-hl.de",
    },
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "LTM Transport und Autoservice",
          "item": "https://ltm-hl.de",
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Selbsthilfewerkstatt | LTM",
          "item": "https://ltm-hl.de/diy",
        }
      ],
    },
  }
  export const ContactPageMetaData = {
    title: "Kontakt | LTM",
    meta: [
      {
        name: "description",
        content: "Kontaktieren Sie uns für Fragen zu Transportern, Autoreparaturen oder unserer Selbsthilfewerkstatt. Wir stehen Ihnen gerne zur Verfügung, um Ihre Anforderungen zu besprechen.",
      },
      {
        name: "keywords",
        content: "Kontakt, Transporter mieten, KFZ Werkstatt, Selbsthilfewerkstatt, Lübeck, Auto Reparatur, Kundenservice",
      },
      {
        property: "og:title",
        content: "Kontakt – LTM Transport und Autoservice",
      },
      {
        property: "og:description",
        content: "Haben Sie Fragen? Kontaktieren Sie uns für Informationen zu Transportern, Werkstätten und Autoreparaturen. Wir helfen Ihnen gerne weiter.",
      },
      {
        property: "og:image",
        content: "https://ltm-hl.de/assets/images/logo/logo.png",
      },
      {
        property: "og:url",
        content: "https://ltm-hl.de/contact",
      },
      {
        property: "og:type",
        content: "website",
      },
    ],
    canonical: "https://ltm-hl.de/contact",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Kontakt – LTM Transport und Autoservice",
      description: "Kontaktieren Sie uns für Anfragen zu Transportern, Reparaturen oder Selbsthilfewerkstätten in Lübeck.",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "+49 15780456850",
        email:"info@ltm-hl.de",
        contactType: "customer service",
        areaServed: "DE",
        availableLanguage: "de",
      },
      provider: {
        "@type": "Organization",
        name: "LTM Transport und Autoservice",
        url: "https://ltm-hl.de",
      },
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "LTM Transport und Autoservice",
          "item": "https://ltm-hl.de",
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Kontakt | LTM",
          "item": "https://ltm-hl.de/contact",
        }
      ],
    },
  }
  export const AboutUsPageMetaData = {
    title: "Über uns | LTM",
    meta: [
      {
        name: "description",
        content: "Erfahren Sie mehr über unsere Geschichte, Mission und Werte. Bei LTM bieten wir hochwertige Transport- und Autoservices mit einem Fokus auf Kundenzufriedenheit und Zuverlässigkeit.",
      },
      {
        name: "keywords",
        content: "Über uns, Transport, Autoservice, KFZ Werkstatt, Selbsthilfewerkstatt, Lübeck, Unternehmen, LTM",
      },
      {
        property: "og:title",
        content: "Über uns – LTM Transport und Autoservice",
      },
      {
        property: "og:description",
        content: "Lernen Sie unser Unternehmen kennen und erfahren Sie mehr über unsere Werte, unser Team und unsere Geschichte in der Transport- und Autoservicebranche.",
      },
      {
        property: "og:image",
        content: "https://ltm-hl.de/assets/images/logo/logo.png",
      },
      {
        property: "og:url",
        content: "https://ltm-hl.de/aboutus",
      },
      {
        property: "og:type",
        content: "website",
      },
    ],
    canonical: "https://ltm-hl.de/aboutus",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "LTM Transport und Autoservice",
      description: "Erfahren Sie mehr über unser Unternehmen, unsere Werte und wie wir qualitativ hochwertige Transport- und Autoservices anbieten.",
      provider: {
      "@type": "Organization",
      name: "LTM Transport und Autoservice",
      url: "https://ltm-hl.de",
    },
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "LTM Transport und Autoservice",
          "item": "https://ltm-hl.de",
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Über uns | LTM",
          "item": "https://ltm-hl.de/aboutus",
        }
      ],
    },
  }
  export const CreditsPageMetaData = {
    title: "Credits | LTM",
    meta: [
      {
        name: "description",
        content: "Auf dieser Seite finden Sie die Danksagungen und Credits für die Ressourcen und Tools, die auf unserer Website verwendet wurden. Wir schätzen die Unterstützung und die großartige Arbeit der Urheber und Quellen, die unsere Dienstleistungen und Inhalte bereichern.",
      },
      {
        name: "keywords",
        content: "Credits, Danksagungen, Ressourcen, Urheber, Tools, Open Source, Transport, Autoservice, Lübeck, LTM",
      },
      {
        property: "og:title",
        content: "Credits | LTM",
      },
      {
        property: "og:description",
        content: "Erfahren Sie mehr über die Ressourcen und Urheber, die unsere Webseite bereichern. Wir bedanken uns bei allen, die zur Entwicklung unserer Plattform beigetragen haben.",
      },
      {
        property: "og:image",
        content: "https://ltm-hl.de/assets/images/logo/logo.png",
      },
      {
        property: "og:url",
        content: "https://ltm-hl.de/credits",
      },
      {
        property: "og:type",
        content: "website",
      },
    ],
    canonical: "https://ltm-hl.de/credits",
    structuredData: {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "Credits | LTM",
      description: "Auf dieser Seite finden Sie die Danksagungen und Credits für die Ressourcen, die auf unserer Website verwendet wurden.",
      provider: {
      "@type": "Organization",
      name: "LTM Transport und Autoservice",
      url: "https://ltm-hl.de",
    },
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "LTM Transport und Autoservice",
          "item": "https://ltm-hl.de",
        },
        {
          "@type": "ListItem",
          "position": 2,
          "name": "Credits | LTM",
          "item": "https://ltm-hl.de/credits",
        }
      ],
    },
  }

  export const NotFoundPageMetaData = {
    title: "404 | LTM",
    meta: [
      {
        name: "description",
        content: "404",
      },
    ],
  }

  export const CopyrightPageMetaData = {
    title: "Impressum | LTM",
    meta: [
      {
        name: "description",
        content: "Impressum",
      },
    ],
  }
  export const CookiesPageMetaData = {
    title: "Cookies| LTM",
    meta: [
      {
        name: "description",
        content: "Cookies",
      },
    ],
  }
  export const PrivacyPageMetaData = {
    title: "Datenschutz| LTM",
    meta: [
      {
        name: "description",
        content: "Datenschutz",
      },
    ],
  }
