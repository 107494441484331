import { Helmet } from "react-helmet-async";
import { ColumnContainer, Label, LabelL, LabelXXL, LimitedContainerMedium, Panel, SafePanel, Space } from "../components/Ui/Ui";
import { CopyrightPageMetaData } from "../data/MetaData";
import { copyrightPageMainData } from "../data/CopyrightData";
const Copyright = () => {
  return (
    <>
       <Helmet>
        <title>{CopyrightPageMetaData.title}</title>
        {CopyrightPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property ? { property: meta.property } : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={CopyrightPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(CopyrightPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }}>
        <SafePanel>
          <ColumnContainer>
            <LabelXXL color="black" fontWeight="900"
            >
              {copyrightPageMainData.title}
            </LabelXXL>
            <Space style={{height:"2rem"}}/>

            <ColumnContainer>
                  <LabelL style={{color: "black", fontWeight:"600"}}>
                    {copyrightPageMainData.copyright.title}
                  </LabelL>
                 
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright.name}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright.adresse1}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright.adresse2}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright.telefon}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright.email}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright.tax}
                  </Label>
             
                  <Space style={{height:"2rem"}}/>
                  </ColumnContainer>

                  <ColumnContainer>
                  <LabelL style={{color: "black", fontWeight:"600"}}>
                    {copyrightPageMainData.copyright2.title}
                  </LabelL>
          
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright2.name1}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright2.name2}
                  </Label>
            
                  <Space style={{height:"2rem"}}/>
                  </ColumnContainer>

                  <ColumnContainer>
                  <LabelL style={{color: "black", fontWeight:"600"}}>
                    {copyrightPageMainData.copyright3.title}
                  </LabelL>
              
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright3.desc1}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright3.desc2}
                  </Label>
                  <Label style={{color: "black"}}>
                    {copyrightPageMainData.copyright3.desc3}
                  </Label>
          
                  <Space style={{height:"2rem"}}/>
                  </ColumnContainer>
              <Space style={{height:"2rem"}}/>
     
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default Copyright;
