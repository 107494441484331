import { Helmet } from "react-helmet-async";
import {
  ColumnContainer,
  Label,
  LabelL,
  LabelXXL,
  LimitedContainerMedium,
  Panel,
  SafePanel,
  Space,
} from "../components/Ui/Ui";
import { PrivacyPageMetaData } from "../data/MetaData";
import { privacyPageMainData } from "../data/PrivacyData";
const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>{PrivacyPageMetaData.title}</title>
        {PrivacyPageMetaData.meta.map((meta, index) => (
          <meta
            key={index}
            {...(meta.property
              ? { property: meta.property }
              : { name: meta.name })}
            content={meta.content}
          />
        ))}
        <link rel="canonical" href={PrivacyPageMetaData.canonical} />
        <script type="application/ld+json">
          {JSON.stringify(PrivacyPageMetaData.structuredData)}
        </script>
      </Helmet>
      <Panel style={{ background: "white" }}>
        <SafePanel>
          <ColumnContainer>
            <LabelXXL color="black" fontWeight="900">
              {privacyPageMainData.title}
            </LabelXXL>
            <Space style={{ height: "2rem" }} />
            {privacyPageMainData.privacyData.map((element, index) => (
              <ColumnContainer>
                <LabelL
                  style={{ color: "black", fontWeight: "600" }}
                  key={index}
                >
                  {element.title}
                </LabelL>
                {element.descriptions.map((element2, index) => (
                  <ColumnContainer>
                    <LimitedContainerMedium>
                      <Label style={{ color: "black" }} key={index}>
                        {element2}
                      </Label>
                    </LimitedContainerMedium>
                 
                  </ColumnContainer>
                ))}
                <Space style={{ height: "2rem" }} />
              </ColumnContainer>
            ))}
         
          </ColumnContainer>
        </SafePanel>
      </Panel>
    </>
  );
};

export default Privacy;
