export const copyrightPageMainData = {
  title: "Impressum",

  copyright: {
    title: "Anbieter",
    name: "LTM Transportservice GbR",
    adresse1: "Hinter den Kirchkasten 35",
    adresse2: "23560 Lübeck",
    telefon: "Telefon: +4915780456850",
    email: "E-mail: Info@ltm-hl.de",
    tax: "USt.-IdNr.: DE 2217206752",
  },

  copyright2: {
    title: "Geschäftsführung",
    name1: "Waled Kamala",
    name2: "Iris Rouminatsev",
  },
  copyright3: {
    title: "Information zur Online-Streitbeilegung",
    desc1:
      "Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Diese Plattform soll als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten dienen, die vertragliche Verpflichtungen von Online-Kaufverträgen und Online-Dienstverträgen betreffen.",
    desc2:
      "Die Plattform ist unter https://ec.europa.eu/consumers/odr/ erreichbar.",
    desc3: "Wir sind jedoch weder verpflichtet noch bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.",
  },
};
